import "vuetify/styles";
import { createVuetify } from "vuetify";
import { fa } from "vuetify/locale";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

const defaultLightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#003064",
    "primary-dark": "#183462",
    secondary: "#0073ef",
    error: "#FF3D32",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "blue-dark": "#465d81",
  },
  // display: {
  //   mobileBreakpoint: "sm",
  //   thresholds: {
  //     xs: 0,
  //     sm: 640,
  //     md: 768,
  //     lg: 1024,
  //     xl: 1280,
  //   },
  // },
};

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "fa",
    fallback: "fa",
    messages: { fa },
    rtl: { fa: true },
  },
  theme: {
    defaultTheme: "defaultLightTheme",
    themes: {
      defaultLightTheme,
    },
  }
});

export default vuetify;
