<script setup lang="ts">
import { watch } from "vue";
import { useRoute } from "vue-router";
import { useGlobalStore } from "./stores/globalStore";
import type { FactoryProductInfoType } from "./services/store/store.types";

const route = useRoute();

watch(
  () => route.path,
  () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    useGlobalStore().factoryProductInfo = {} as FactoryProductInfoType;
  }
);
</script>

<template>
  <component :is="$route.meta.layout || 'div'" class="w-full h-auto">
    <RouterView />
  </component>
</template>
