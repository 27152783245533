import type { FactoryProductInfoType } from "@/services/store/store.types";
import { defineStore } from "pinia";

export const useGlobalStore = defineStore("globalStore", {
  state: () => {
    return {
      updateCardList: false,
      updateAddressList: false,
      updateCustomerList: false,
      userCustomerId: undefined,
      orderNewData: {},
      factoryProductInfo: {} as FactoryProductInfoType,
    };
  },
});
