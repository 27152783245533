<template>
  <v-app app>
    <v-navigation-drawer v-model="rightDrawer" width="245" class="!z-10">
      <div class="w-full h-[84px] flex items-center border-b px-6">
        <a href="https://ayandehcement.com/">
          <img src="~@/assets/images/logo.webp" alt="logo" class="w-[170px] h-[48px]" />
        </a>
      </div>
      <div class="flex flex-col gap-y-3 pt-8 px-6">
        <RouterLink
          v-for="(item, index) in menuItems"
          v-show="item.display"
          :key="index"
          v-ripple
          :to="item.to"
          class="h-[40px] flex items-center gap-x-2 !p-2 rounded-lg"
          :class="route.path === item.to ? 'bg-[#e6f1fd]' : ''"
        >
          <BaseIcon :icon="item.icon" :fill="$route.path === item.to" class="!text-blue-dark-500" />
          <span class="!text-sm !font-bold !text-primary-900">{{ item.title }}</span>
        </RouterLink>
      </div>
      <div class="fixed bottom-6 right-6 flex flex-col gap-3 text-blue-dark-200">
        <div class="flex items-center gap-2">
          <BaseIcon icon="encrypted" />
          <span class="text-sm">
            {{ authStore.userInfo.last_name }} -
            {{ `کد اشتراک ${authStore.userInfo.id}` }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <BaseIcon icon="copyright" />
          <span class="text-sm"> سیمان آینده - نسخه 1.4.9 </span>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main class="block">
      <div
        class="w-full h-[84px] sticky top-0 bg-white z-10 hidden md:flex justify-between items-center px-6 !shadow-none border-b"
      >
        <div class="flex items-center gap-x-3">
          <div class="flex cursor-pointer" @click.stop="rightDrawer = !rightDrawer">
            <BaseIcon icon="menu" class="text-primary-900" />
          </div>
          <span class="text-sm text-primary-900 font-bold">
            {{ pageTitle }}
          </span>
        </div>
        <v-menu transition="slide-x-transition" scroll-strategy="close">
          <template v-slot:activator="{ props }">
            <div
              v-if="authStore.userInfo.avatar_url"
              v-bind="props" :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
              class="border-[1.3px] border-primary-500 w-10 h-10 rounded-lg cursor-pointer"
            >
            </div>
            <div
              v-else
              v-bind="props"
              class="w-10 h-10 border-[1.3px] border-primary-500 rounded-lg grid place-items-center cursor-pointer"
            >
              <BaseIcon icon="account-circle" fill class="text-primary-500" />
            </div>
          </template>

          <v-list width="200" class="!p-2 border">
            <v-list-item link>
              <RouterLink to="/user-account">
                <v-list-item-title>
                  <BaseIcon icon="account-circle" fill class="ml-2 text-primary-500 align-middle text-2xl" />
                  {{ "پروفایل کاربر" }}
                </v-list-item-title>
              </RouterLink>
            </v-list-item>
            <v-list-item link to="/wallet">
              <v-list-item-title>
                <BaseIcon icon="wallet" class="ml-2 text-primary-500 align-middle text-2xl" />
                تاریخچه کیف پول
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logOut">
              <v-list-item-title>
                <BaseIcon icon="power" class="ml-2 text-red-500 align-middle text-2xl" />
                {{ "خروج" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        v-if="!!($route.path === '/dashboard')"
        class="w-full h-[64px] sticky top-0 bg-white z-10 md:hidden flex justify-between items-center px-5 !shadow-none border-b"
      >
        <a href="https://ayandehcement.com/">
          <div class="w-[150px] h-10 relative">
            <TransitionGroup name="slide-up">
              <img v-if="timer > 0" src="~@/assets/images/logo.webp" alt="logo" class="absolute w-auto h-10" />
              <img v-if="timer === 0" src="~@/assets/images/logo-mobile.webp" alt="logo" class="absolute w-auto h-10" />
            </TransitionGroup>
          </div>
        </a>
        <v-menu transition="slide-x-transition" scroll-strategy="close">
          <template v-slot:activator="{ props }">
            <div
              v-if="authStore.userInfo.avatar_url"
              v-bind="props" :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
              class="border-[1.3px] border-primary-500 w-10 h-10 rounded-lg cursor-pointer"
            >
            </div>
            <div
              v-else
              v-bind="props"
              class="w-10 h-10 border-[1.3px] border-primary-500 rounded-lg grid place-items-center cursor-pointer"
            >
              <BaseIcon icon="account-circle" fill class="text-primary-500" />
            </div>
          </template>

          <v-list width="200" class="!p-2 border">
            <v-list-item link>
              <RouterLink to="/user-account">
                <v-list-item-title>
                  <BaseIcon icon="account-circle" fill class="ml-2 text-primary-500 align-middle text-2xl" />
                  {{ "پروفایل کاربر" }}
                </v-list-item-title>
              </RouterLink>
            </v-list-item>
            <v-list-item link to="/wallet">
              <v-list-item-title>
                <BaseIcon icon="wallet" class="ml-2 text-primary-500 align-middle text-2xl" />
                تاریخچه کیف پول
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logOut">
              <v-list-item-title>
                <BaseIcon icon="power" class="ml-2 text-red-500 align-middle text-2xl" />
                {{ "خروج" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="max-w-full h-full md:h-auto">
        <slot />
      </div>
      <div
        v-if="route.path !== '/invoices' && route.path !== '/store' && route.path !== '/wallet'"
        class="sticky bottom-0 left-0 w-full h-[82px] grid md:hidden place-items-center px-5"
      >
        <div class="w-full flex items-end justify-between z-10">
          <div class="w-[40%] flex items-center justify-between">
            <RouterLink to="/dashboard" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2">
              <div class="w-10 flex flex-col items-center gap-y-1 m-auto pl-6">
                <BaseIcon icon="dashboard" :fill="route.path === '/dashboard'" />
                <span class="font-bold text-xs">داشبورد</span>
              </div>
            </RouterLink>
            <RouterLink to="/cards" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2">
              <div class="w-10 flex flex-col items-center gap-y-1 m-auto pl-4">
                <BaseIcon size="24" icon="receipt" :fill="route.path === '/cards'" />
                <span class="font-bold text-xs">کارت</span>
              </div>
            </RouterLink>
          </div>
          <RouterLink to="/store" exact-active-class="!text-primary-500" class="text-blue-dark-300">
            <div
              class="absolute left-1/2 -translate-x-1/2 top-[-24px] z-10 w-12 h-12 grid place-items-center rounded-full"
              :class="route.path === '/store' ? 'bg-primary-500' : 'bg-white border-[1px]'"
            >
              <BaseIcon
                icon="store"
                class="!text-[32px]"
                :class="route.path === '/store' ? 'text-white' : 'text-blue-dark-400'"
              />
            </div>
            <div class="flex flex-col h-full">
              <span class="font-bold text-xs">فروشگاه</span>
            </div>
          </RouterLink>

          <div class="w-[40%] flex items-center justify-between">
            <RouterLink to="/orders" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2">
              <div class="flex flex-col items-center gap-y-1 m-auto pr-6">
                <BaseIcon icon="local-shipping" :fill="route.path === '/orders'" />
                <span class="font-bold text-xs">سفارش</span>
              </div>
            </RouterLink>
            <div class="w-1/2 flex flex-col items-center gap-y-1 text-blue-dark-300 m-auto pr-6" @click="moreItemsMenu = true">
              <BaseIcon icon="menu" />
              <span class="font-bold text-xs">بیشتر</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[1px] right-1/2 translate-x-1/2 w-full h-full">
          <img
            src="~@/assets/images/bg-mobile-menu.svg"
            class="w-full h-full object-cover drop-shadow-[0_0_12px_rgba(0,0,0,0.12)]"
          />
        </div>
      </div>
    </v-main>
    <MoreMenuBottomSheet v-model="moreItemsMenu" @log-out="logOut" />
  </v-app>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/authStore";
import { isEmpty } from "lodash";
import { onMounted, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import MoreMenuBottomSheet from "./components/MoreMenuBottomSheet.vue";

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const display = useDisplay();

const rightDrawer = ref(display.mdAndUp);
const pageTitle = ref("");
const menuItems = [
  {
    icon: "dashboard",
    title: "داشبورد",
    to: "/dashboard",
    display: true
  },
  {
    icon: "store",
    title: "فروشگاه",
    to: "/store",
    display: true
  },
  {
    icon: "receipt",
    title: "کارت ها",
    to: "/cards",
    display: true
  },
  {
    icon: "local-shipping",
    title: "سفارش ها",
    to: "/orders",
    display: true
  },
  {
    icon: "receipt-long",
    title: "صورتحساب",
    to: "/invoices",
    display: true
  },
  {
    icon: "",
    title: "کیف پول",
    to: "/wallet",
    display: false
  },
  {
    icon: "",
    title: "پروفایل",
    to: "/user-account",
    display: false
  },
];
const moreItemsMenu = ref(false);
const timerId = ref<number | undefined>(0);
const timer = ref(1);

const startTimer = () => {
  if (timerId.value) {
    clearInterval(timerId.value);
  }
  timer.value = 3;
  timerId.value = setInterval(() => {
    timer.value--;
    if (timer.value <= 0) {
      clearInterval(timerId.value);
      timerId.value = undefined;
    }
  }, 1000);
};

onMounted(() => {
  if (!authStore.isLoggedIn) {
    router.push({ name: "auth" });
  }
  pageTitle.value = menuItems.find((item) => item.to === route.path)?.title as string;
  startTimer();
});

watch(route, (val) => {
  pageTitle.value = menuItems.find((item) => item.to === route.path)?.title as string;
});

watch(route, () => {
  if (!authStore.isLoggedIn) {
    router.push({ name: "auth" });
  }
  if (route.path === "/dashboard") {
    startTimer();
  }
});

watch(
  () => authStore.userInfo,
  (val) => {
    if (!authStore.isLoggedIn) {
      router.push({ name: "auth" });
    }
  },
  {
    deep: true,
  }
);

const logOut = () => {
  moreItemsMenu.value = false;
  authStore.logout();
  router.push("/auth");
};
</script>

<style scoped lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
