import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/core/plugins/vuetify";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import dayjs from "dayjs";
import jalaliday from "jalaliday";
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";
import BaseIcon from "@/components/base/BaseIcon.vue";
import { createGtm } from "@gtm-support/vue-gtm";
import "./assets/css/main.css";
import "./assets/css/styles.css";

import VueApexCharts from "vue3-apexcharts";

dayjs.extend(jalaliday);

const app = createApp(App);

app.use(VueApexCharts);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.component("DatePicker", Vue3PersianDatetimePicker);
app.component("BaseIcon", BaseIcon);

app.use(Vue3Toasity, {
  theme: "colored",
  autoClose: 3000,
  rtl: true,
} as ToastContainerOptions);

app.use(
  createGtm({
    id: "GTM-TPG4C9DX",
    vueRouter: router,
  })
);

app.use(pinia);
app.use(router);
app.use(vuetify);

app.mount("#app");
